<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        Consider looking at PIPE (Product innovation and Ecosystem)
      </v-col>
      <v-col cols="3"> </v-col>
      <v-col cols="3"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",
  props: {},
  components: {},

  data: () => ({}),
  mounted() {},

  methods: {},
};
</script>

