<template>
  <v-container>
    <!-- BIM Q1 -->
    <v-row>
      <v-col cols="6">
        Has the client provided an Employer Information Requirements (EIR) ?
      </v-col>
      <v-col cols="3">
        <v-radio-group
          v-model="EIRSupplied"
          @change="updateStore(EIRSupplied, 'EIRSupplied', false)"
        >
          <v-radio label="Yes" value="true"></v-radio>
          <v-radio label="No or not sure" value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="3">
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >EIRs are commonly provided by the client and the BEP is developed
            as a response. The EIRs provide client aspirations for data and
            information exchange and management.</span
          >
        </v-tooltip>
        <v-btn
          color="grey lighten-2"
          dark
          elevation="0"
          class="grey--text"
          rounded
          small
          target="_blank"
          href="https://arup.sharepoint.com/teams/digital-design-development/Published%20Guidance%20Documents/Guidance%20Note%20-%20BIM%20Scope.pdf#search=EIR"
        >
          LEARN MORE
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="EIRSupplied == 'true'">
      <v-row>
        <v-col cols="6"> Please add a link </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="EIRSuppliedTrue"
            @change="updateStore(EIRSuppliedTrue, 'EIRSuppliedTrue', false)"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div v-if="EIRSupplied == 'false'">
      <v-row>
        <v-col cols="6">
          EIR is an essential document for the successful implementation of a
          project. If appropriate we recommend PM to contact client to discuss
          Arup providing this scope on employer's behalf, but at the very least
          we should have a copy of this document.
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="EIRSuppliedFalse"
            :items="personnelList"
            item-text="Name"
            item-value="Email"
            return-object
            label="Please select..."
            @change="
              updateOtherActionsList(
                EIRSuppliedFalse,
                'Check that the client has provided Employer Information Requirements (EIR)'
              );
              updateStore(EIRSuppliedFalse, 'EIRSuppliedFalse', true);
            "
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <!-- BIM Q2 -->
    <v-row>
      <v-col cols="6">
        Is there a BIM execution plan for the project ? (BEP)
      </v-col>
      <v-col cols="3">
        <v-radio-group
          v-model="BEPSupplied"
          @change="updateStore(BEPSupplied, 'BEPSupplied', false)"
        >
          <v-radio label="Yes" value="true"></v-radio>
          <v-radio label="No or not sure" value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="3">
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span>Click 'Learn More' for templates and guidance.</span>
        </v-tooltip>
        <v-btn
          color="grey lighten-2"
          dark
          elevation="0"
          class="grey--text"
          rounded
          small
          target="_blank"
          href="https://arup.sharepoint.com/sites/bim/SitePages/Arup-Templates.aspx"
        >
          LEARN MORE
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="BEPSupplied == 'true'">
      <v-row>
        <v-col cols="6"> Please add a link </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="BEPSuppliedTrueA"
            @change="updateStore(BEPSuppliedTrueA, 'BEPSuppliedTrueA', false)"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6"> Is the BEP ISO 19650 compliant ? </v-col>
        <v-col cols="3">
          <v-radio-group
            v-model="BEPSuppliedTrueB"
            @change="updateStore(BEPSuppliedTrueB, 'BEPSuppliedTrueB', false)"
          >
            <v-radio label="Yes" value="true"></v-radio>
            <v-radio label="No or not sure" value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="3">
          <v-tooltip top max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span>Click 'Learn More' for templates and guidance.</span>
          </v-tooltip>
          <v-btn
            color="grey lighten-2"
            dark
            elevation="0"
            class="grey--text"
            rounded
            small
            target="_blank"
            href="https://arup.sharepoint.com/sites/bim/SitePages/Arup-Templates.aspx"
          >
            LEARN MORE
          </v-btn>
        </v-col>
      </v-row>

      <div v-if="BEPSuppliedTrueB == 'false'">
        <v-row>
          <v-col cols="6"> Assign an action item for this </v-col>
          <v-col cols="6">
            <v-select
              v-model="BEPSuppliedFalseB"
              :items="personnelList"
              item-text="Name"
              item-value="Email"
              return-object
              label="Please select..."
              @change="
                updateOtherActionsList(
                  BEPSuppliedFalseB,
                  'Make sure that the BEP is ISO 19650 compliant'
                );
                updateStore(BEPSuppliedFalseB, 'BEPSuppliedFalseB', true);
              "
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-if="BEPSupplied == 'false'">
      <v-row>
        <v-col cols="6">
          Buildings and Infrastructure projects should have BIM execution plans.
          A BIM execution plan should be developed.
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="BEPSuppliedFalseA"
            :items="personnelList"
            item-text="Name"
            item-value="Email"
            return-object
            label="Please select..."
            @change="
              updateOtherActionsList(
                BEPSuppliedFalseA,
                'A BIM execution plan should be developed'
              );
              updateStore(BEPSuppliedFalseA, 'BEPSuppliedFalseA', true);
            "
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <!-- BIM Q3 -->
    <v-row>
      <v-col cols="6">
        Are there agreed Levels of Development/Detail for each project stage?
      </v-col>
      <v-col cols="3">
        <v-radio-group
          v-model="LODAgreements"
          @change="updateStore(LODAgreements, 'LODAgreements', false)"
        >
          <v-radio label="Yes" value="true"></v-radio>
          <v-radio label="No or not sure" value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="3">
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >LOD often refers to level of development or level of detail. It is
            primarily used to describe the level of detail at which the
            consultant is expected to develop their model or information for
            each stage, it is important that the PM and PD are aware of the
            expectations of the client when signing the contract. As a brief
            overview, the American Institute of Architects and the NBS toolkit
            provide background and information of the expected LODs at each
            stage. Note that the AIA uses 100, 200, 300 whereas NBS uses 1, 2,
            3...</span
          >
        </v-tooltip>
        <v-btn
          color="grey lighten-2"
          dark
          elevation="0"
          class="grey--text"
          rounded
          small
          target="_blank"
          href="https://arup.sharepoint.com/sites/bim/SitePages/Level-of-Development.aspx"
        >
          LEARN MORE
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="LODAgreements == 'true'"></div>
    <div v-if="LODAgreements == 'false'">
      <v-row>
        <v-col cols="6"> Assign an action item for this </v-col>
        <v-col cols="6">
          <v-select
            v-model="LODAgreementsFalse"
            :items="personnelList"
            item-text="Name"
            item-value="Email"
            return-object
            label="Please select..."
            @change="
              updateOtherActionsList(
                LODAgreementsFalse,
                'Find out the agreed Levels of Development/Detail for each project stage'
              );
              updateStore(LODAgreementsFalse, 'LODAgreementsFalse', true);
            "
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <!-- BIM Q4 -->
    <v-row>
      <v-col cols="6">
        Are there opportunities for an OpenBIM approach? eg. IFC approach
      </v-col>
      <v-col cols="3">
        <v-radio-group
          v-model="OpenBIMApproach"
          @change="updateStore(OpenBIMApproach, 'OpenBIMApproach', false)"
        >
          <v-radio label="Yes" value="true"></v-radio>
          <v-radio label="No or not sure" value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="3">
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >OpenBIM is a specific term that provides us the option to use the
            BIM authoring tool that suits our needs rather than defaulting to an
            often sub-optimal solution that limts interoperability and
            automation. We often do not challenge on projects and therefore do
            not realise the opportunity. OpenBIM is an international initiative
            led by BuildingSmart International.</span
          >
        </v-tooltip>
        <v-btn
          color="grey lighten-2"
          dark
          elevation="0"
          class="grey--text"
          rounded
          small
          target="_blank"
          href="https://www.buildingsmart.org/about/openbim/"
        >
          LEARN MORE
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="OpenBIMApproach == 'true'">
      <v-row>
        <v-col cols="6"> Add information </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="OpenBIMApproachTrue"
            @change="
              updateStore(OpenBIMApproachTrue, 'OpenBIMApproachTrue', false)
            "
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>

    <div v-if="OpenBIMApproach == 'false'">
      <v-row>
        <v-col cols="6"> Assign an action item for this </v-col>
        <v-col cols="6">
          <v-select
            v-model="OpenBIMApproachFalse"
            :items="personnelList"
            item-text="Name"
            item-value="Email"
            return-object
            label="Please select..."
            @change="
              updateOtherActionsList(
                OpenBIMApproachFalse,
                'Find opportunities for an OpenBIM approach'
              );
              updateStore(OpenBIMApproachFalse, 'OpenBIMApproachFalse', true);
            "
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <!-- BIM Q5 -->
    <v-row>
      <v-col cols="6">
        Have you reviewed the BIM Maturity Measure to inform this review?
      </v-col>
      <v-col cols="3">
        <v-radio-group
          v-model="BIMmmReview"
          @change="updateStore(BIMmmReview, 'BIMmmReview', false)"
        >
          <v-radio label="Yes" value="true"></v-radio>
          <v-radio label="No or not sure" value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="3">
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >We recommend you use the tool to set a BIM Maturity Measure target
            for the project.</span
          >
        </v-tooltip>
        <v-btn
          color="grey lighten-2"
          dark
          elevation="0"
          class="grey--text"
          rounded
          small
          target="_blank"
          href="http://bimmaturitymeasure.arup.com/"
        >
          LEARN MORE
        </v-btn>
      </v-col>
    </v-row>

    <div v-if="BIMmmReview == 'true'">
      <v-row>
        <v-col cols="6">
          Add the link to the completed BIM Maturity Measure
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="BIMmmReviewTrue"
            @change="updateStore(BIMmmReviewTrue, 'BIMmmReviewTrue', false)"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </div>

    <div v-if="BIMmmReview == 'false'">
      <v-row>
        <v-col cols="6"> Assign an action item for this </v-col>
        <v-col cols="6">
          <v-select
            v-model="BIMmmReviewFalse"
            :items="personnelList"
            item-text="Name"
            item-value="Email"
            return-object
            label="Please select..."
            @change="
              updateOtherActionsList(
                BIMmmReviewFalse,
                'Review the BIM Maturity Measure'
              );
              updateStore(BIMmmReviewFalse, 'BIMmmReviewFalse', true);
            "
          ></v-select>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  name: "App",
  props: {},
  components: {},

  data: () => ({}),
  mounted() {},
  watch: {},
  methods: {
    async updateStore(newVal, property, actionListFlag) {
      this.$store.state.plan.DigitalPlan[property] = newVal;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        property
      );
    },
    async updateOtherActionsList(item, actionDescription) {
      // other action already exists
      let index = 0;
      // check if other actions store already has entries
      if (
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary.length > 0
      ) {
        // check if this action already exists
        index =
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary.findIndex(
            (x) => x.action == actionDescription
          );
        if (index > -1) {
          // if it does, edit it.
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary[
            index
          ].assignee = item;
        } else {
          // if it doesnt, push this action to the list
          this.$store.state.plan.DigitalPlan.OtherActionsListSummary.push({
            action: actionDescription,
            assignee: item,
            status: "Not started",
          });
        }
      } else {
        // no actions exist yet so just push it.
        this.$store.state.plan.DigitalPlan.OtherActionsListSummary.push({
          action: actionDescription,
          assignee: item,
          status: "Not started",
        });
      }
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "OtherActionsListSummary"
      );
    },
  },
  computed: {
    EIRSupplied: {
      get() {
        if (
          this.$store.state.plan.DigitalPlan.EIRSupplied == true ||
          this.$store.state.plan.DigitalPlan.EIRSupplied == "true"
        ) {
          return "true";
        }
        if (
          this.$store.state.plan.DigitalPlan.EIRSupplied == false ||
          this.$store.state.plan.DigitalPlan.EIRSupplied == "false"
        ) {
          return "false";
        }
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.EIRSupplied = val;
      },
    },

    EIRSuppliedTrue: {
      get() {
        return this.$store.state.plan.DigitalPlan.EIRSuppliedTrue;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.EIRSuppliedTrue = val;
      },
    },

    EIRSuppliedFalse: {
      get() {
        return this.$store.state.plan.DigitalPlan.EIRSuppliedFalse;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.EIRSuppliedFalse = val;
      },
    },

    BEPSupplied: {
      get() {
        if (
          this.$store.state.plan.DigitalPlan.BEPSupplied == true ||
          this.$store.state.plan.DigitalPlan.BEPSupplied == "true"
        ) {
          return "true";
        }
        if (
          this.$store.state.plan.DigitalPlan.BEPSupplied == false ||
          this.$store.state.plan.DigitalPlan.BEPSupplied == "false"
        ) {
          return "false";
        }
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.BEPSupplied = val;
      },
    },

    BEPSuppliedTrueA: {
      get() {
        return this.$store.state.plan.DigitalPlan.BEPSuppliedTrueA;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.BEPSuppliedTrueA = val;
      },
    },
    BEPSuppliedFalseA: {
      get() {
        return this.$store.state.plan.DigitalPlan.BEPSuppliedFalseA;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.BEPSuppliedFalseA = val;
      },
    },
    BEPSuppliedTrueB: {
      get() {
        if (
          this.$store.state.plan.DigitalPlan.BEPSuppliedTrueB == true ||
          this.$store.state.plan.DigitalPlan.BEPSuppliedTrueB == "true"
        ) {
          return "true";
        }
        if (
          this.$store.state.plan.DigitalPlan.BEPSuppliedTrueB == false ||
          this.$store.state.plan.DigitalPlan.BEPSuppliedTrueB == "false"
        ) {
          return "false";
        }
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.BEPSuppliedTrueB = val;
      },
    },
    BEPSuppliedFalseB: {
      get() {
        return this.$store.state.plan.DigitalPlan.BEPSuppliedFalseB;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.BEPSuppliedFalseB = val;
      },
    },
    LODAgreements: {
      get() {
        if (
          this.$store.state.plan.DigitalPlan.LODAgreements == true ||
          this.$store.state.plan.DigitalPlan.LODAgreements == "true"
        ) {
          return "true";
        }
        if (
          this.$store.state.plan.DigitalPlan.LODAgreements == false ||
          this.$store.state.plan.DigitalPlan.LODAgreements == "false"
        ) {
          return "false";
        }
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.LODAgreements = val;
      },
    },
    LODAgreementsFalse: {
      get() {
        return this.$store.state.plan.DigitalPlan.LODAgreementsFalse;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.LODAgreementsFalse = val;
      },
    },
    OpenBIMApproach: {
      get() {
        if (
          this.$store.state.plan.DigitalPlan.OpenBIMApproach == true ||
          this.$store.state.plan.DigitalPlan.OpenBIMApproach == "true"
        ) {
          return "true";
        }
        if (
          this.$store.state.plan.DigitalPlan.OpenBIMApproach == false ||
          this.$store.state.plan.DigitalPlan.OpenBIMApproach == "false"
        ) {
          return "false";
        }
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.OpenBIMApproach = val;
      },
    },

    OpenBIMApproachTrue: {
      get() {
        return this.$store.state.plan.DigitalPlan.OpenBIMApproachTrue;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.OpenBIMApproachTrue = val;
      },
    },
    OpenBIMApproachFalse: {
      get() {
        return this.$store.state.plan.DigitalPlan.OpenBIMApproachFalse;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.OpenBIMApproachFalse = val;
      },
    },
    BIMmmReview: {
      get() {
        if (
          this.$store.state.plan.DigitalPlan.BIMmmReview == true ||
          this.$store.state.plan.DigitalPlan.BIMmmReview == "true"
        ) {
          return "true";
        }
        if (
          this.$store.state.plan.DigitalPlan.BIMmmReview == false ||
          this.$store.state.plan.DigitalPlan.BIMmmReview == "false"
        ) {
          return "false";
        }
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.BIMmmReview = val;
      },
    },
    BIMmmReviewTrue: {
      get() {
        return this.$store.state.plan.DigitalPlan.BIMmmReviewTrue;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.BIMmmReviewTrue = val;
      },
    },
    BIMmmReviewFalse: {
      get() {
        return this.$store.state.plan.DigitalPlan.BIMmmReviewFalse;
      },
      set(val) {
        this.$store.state.plan.DigitalPlan.BIMmmReviewFalse = val;
      },
    },

    personnelList() {
      let group = [];
      if (this.$store.state.plan.ProjectDirector.Name != null) {
        group.push(this.$store.state.plan.ProjectDirector);
      }
      if (this.$store.state.plan.ProjectManager.Name != null) {
        group.push(this.$store.state.plan.ProjectManager);
      }
      if (this.$store.state.plan.DigitalPlan.DigitalLead.Name != null) {
        this.$store.state.plan.DigitalPlan.DigitalLead;
      }
      if (this.$store.state.plan.DigitalPlan.DigitalFacilitator.Name != null) {
        group.push(this.$store.state.plan.DigitalPlan.DigitalFacilitator);
      }
      this.$store.state.plan.DigitalPlan.OtherAttendees.forEach((element) => {
        if (element.Name != null) {
          group.push(element);
        }
      });
      this.$store.state.plan.DigitalPlan.DisciplineLeads.forEach((element) => {
        group.push(element.Lead);
        if (element.Lead.Name != null) {
          group.push(element.Lead);
        }
      });
      return group;
    },
  },
};
</script>


